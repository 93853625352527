<script lang="ts" setup>
import { ref, toValue, watch } from 'vue'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'
import useEngagementNavigation
  from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import settingsPreviousRouteStore
  from '~/src/UserInterface/Engagement/store/SettingsPreviousRouteStore'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

const { getUser } = useUserState()
const user = getUser()
const { getOrganisation } = useOrganisationState()
const organisation = getOrganisation()
const { getCurrentEngagement, engagements, hasCurrentEngagement, getCurrentPhase, hasCurrentPhase } = useEngagementState()
const currentEngagement = hasCurrentEngagement.value ? getCurrentEngagement() : undefined
const currentPhase = hasCurrentPhase.value ? getCurrentPhase() : undefined

const { getLocalePath } = useNavigation()
const store = settingsPreviousRouteStore()
const closeButtonTo = ref('/')
watch(
  [
    () => store.previousRoute,
    hasCurrentEngagement,
    hasCurrentPhase,
  ],
  ([
    newPreviousRoute,
    newHasCurrentEngagement,
    newHasCurrentPhase,
  ],
  ) => {
    if (newPreviousRoute !== undefined) {
      closeButtonTo.value = getLocalePath(toValue(newPreviousRoute))
    } else if (newHasCurrentEngagement && newHasCurrentPhase) {
      const { engagementDashboardLocalePath } = useEngagementNavigation(toValue(organisation), toValue(getCurrentEngagement()), toValue(getCurrentPhase()))
      closeButtonTo.value = engagementDashboardLocalePath.value
    } else {
      closeButtonTo.value = getLocalePath({ name: 'landing' })
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div class="main-content d-flex flex-column">
    <organisation-navbar-top
      :user
      :organisation
      :engagement="currentEngagement"
      :close-button-to="closeButtonTo"
    />

    <organisation-navbar-dropdown
      :user
      :organisation
      :current-engagement="currentEngagement"
      :engagements="engagements"
      :current-phase="currentPhase"
    />

    <div class="flex-grow-1">
      <slot />
    </div>

    <div
      id="footer"
      class="bg-white w-100"
    >
      <layout-footer />
    </div>
    <portal-target id="app-bottom" />
  </div>
</template>
